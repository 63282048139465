/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pipeline_system_exists__no': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M37.8 10h-2.5c-.9 0-1.4.6-1.6 1.2h-5.2c-4.6 0-8.4 3.8-8.4 8.4v1.5c-.8.2-1.2.9-1.2 1.6v2.5c0 .9.6 1.4 1.2 1.6v1.5c0 .8-.7 1.5-1.5 1.5h-4c-.2-.8-.9-1.2-1.6-1.2h-2.5c-1.1 0-1.6.8-1.6 1.6v6.2c0 1.1.8 1.6 1.6 1.6H13c.9 0 1.4-.6 1.6-1.2h4c4.6 0 8.4-3.8 8.4-8.4v-1.5c.8-.2 1.2-.9 1.2-1.6v-2.5c0-.9-.6-1.4-1.2-1.6v-1.5c0-.8.7-1.5 1.5-1.5h5.2c.2.8.9 1.2 1.6 1.2h2.5c1.1 0 1.6-.8 1.6-1.6v-6.2c0-1.1-.8-1.6-1.6-1.6zM26.3 23.1v1.7H21v-1.7h5.4-.1zM11 36v-5.4h1.7V35.9H11v.1zm14.1-7.7c0 3.5-2.9 6.4-6.4 6.4h-3.9v-2.9h3.9c1.9 0 3.5-1.6 3.5-3.5v-1.5h2.9v1.5zm0-8.6v1.5h-2.9v-1.5c0-3.5 2.9-6.4 6.4-6.4h5.2v2.9h-5.2c-1.9 0-3.5 1.6-3.5 3.5zm12.3-2.3h-1.7V12h1.7v5.4z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7.7 7.7c.4-.4 1-.4 1.4 0l31.2 31.2c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0L7.7 9.1c-.4-.4-.4-1 0-1.4z" _fill="url(#paint0_linear_2632_1514)"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M1 24C1 11.3 11.3 1 24 1s23 10.3 23 23-10.3 23-23 23S1 36.7 1 24zM24 2.9C12.3 2.9 2.9 12.3 2.9 24c0 11.7 9.4 21.1 21.1 21.1 11.7 0 21.1-9.4 21.1-21.1 0-11.7-9.4-21.1-21.1-21.1z" _fill="#000"/><defs><linearGradient id="paint0_linear_2632_1514" x1="4.5" y1="28.9" x2="43.5" y2="19.1" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".5" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
