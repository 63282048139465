/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__asap': {
    width: 50,
    height: 48,
    viewBox: '0 0 50 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.19 34.492c0-6.894 5.613-12.507 12.508-12.507 6.894 0 12.507 5.613 12.507 12.507C48.205 41.387 42.592 47 35.698 47 28.805 47 23.19 41.42 23.19 34.492zm12.508-10.655c-5.872 0-10.656 4.784-10.656 10.655 0 5.903 4.782 10.656 10.656 10.656 5.871 0 10.655-4.784 10.655-10.656 0-5.871-4.784-10.655-10.655-10.655z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M5.041 6.78c-.603 0-1.115.51-1.115 1.152v29.48c0 .657.526 1.152 1.115 1.152h16.553a.963.963 0 110 1.927H5.04C3.376 40.49 2 39.12 2 37.41V7.933C2 6.28 3.32 4.853 5.041 4.853h37.261c1.665 0 3.041 1.37 3.041 3.08v13.699a.963.963 0 01-1.926 0v-13.7c0-.657-.526-1.153-1.115-1.153H5.042z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M12.595 1c.532 0 .963.431.963.963v10.595a.963.963 0 01-1.926 0V1.963c0-.532.431-.963.963-.963zM33.785 1c.532 0 .963.431.963.963v10.595a.963.963 0 11-1.926 0V1.963c0-.532.431-.963.963-.963zM2.963 15.835c0-.532.431-.963.963-.963h39.972a.963.963 0 010 1.926H3.926a.963.963 0 01-.963-.963z" _fill="#000"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M36 28.487c.532 0 .963.432.963.964v6.022a.963.963 0 01-1.926 0v-6.022c0-.532.431-.964.963-.964z" _fill="url(#paint0_linear_1846_2024)"/><path pid="4" d="M37.156 39.768a1.204 1.204 0 11-2.408 0 1.204 1.204 0 012.408 0z" _fill="url(#paint1_linear_1846_2024)"/><defs><linearGradient id="paint0_linear_1846_2024" x1="35.037" y1="36.437" x2="37.436" y2="36.291" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient><linearGradient id="paint1_linear_1846_2024" x1="34.748" y1="40.972" x2="37.581" y2="40.264" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
