/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__apartment': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.368 37.656c-.986 0-1.792.785-1.792 1.793v6.185a.848.848 0 01-1.696 0V39.45a3.48 3.48 0 013.488-3.489 3.503 3.503 0 013.489 3.489v6.185a.848.848 0 01-1.696 0V39.45c0-.98-.814-1.793-1.793-1.793z" _fill="url(#paint0_linear_1844_4234)"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M32.388 20.577a.92.92 0 01.92-.92h10.77c.797 0 1.53.279 2.067.815.536.536.815 1.27.815 2.066V46.08a.92.92 0 11-1.84 0V22.539c0-.364-.122-.612-.276-.766-.155-.155-.402-.276-.766-.276h-10.77a.92.92 0 01-.92-.92zM7.366 7.805a.92.92 0 01.92-.92h5.885a.92.92 0 01.92.92v7.847a.92.92 0 01-.92.92H8.286a.92.92 0 01-.92-.92V7.805zm1.84.92v6.007h4.045V8.725H9.206zM20.137 7.805a.92.92 0 01.92-.92h5.886a.92.92 0 01.92.92v7.847a.92.92 0 01-.92.92h-5.886a.92.92 0 01-.92-.92V7.805zm1.84.92v6.007h4.046V8.725h-4.046zM7.366 22.539a.92.92 0 01.92-.92h5.885a.92.92 0 01.92.92v7.847a.92.92 0 01-.92.92H8.286a.92.92 0 01-.92-.92v-7.847zm1.84.92v6.007h4.045v-6.007H9.206zM20.137 22.539a.92.92 0 01.92-.92h5.886a.92.92 0 01.92.92v7.847a.92.92 0 01-.92.92h-5.886a.92.92 0 01-.92-.92v-7.847zm1.84.92v6.007h4.046v-6.007h-4.046z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M3.116 3.116c-.155.155-.276.402-.276.766V46.04a.92.92 0 01-1.84 0V3.882c0-.798.279-1.531.815-2.067C2.35 1.279 3.085 1 3.882 1h27.424c.798 0 1.531.279 2.067.815.536.536.815 1.27.815 2.067V46.04a.92.92 0 01-1.84 0V3.882c0-.364-.121-.611-.276-.766-.154-.155-.402-.276-.766-.276H3.883c-.364 0-.612.121-.766.276z" _fill="#000"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M1 46.04a.92.92 0 01.92-.92h44.12a.92.92 0 010 1.84H1.92a.92.92 0 01-.92-.92z" _fill="#000"/><defs><linearGradient id="paint0_linear_1844_4234" x1="13.88" y1="46.482" x2="22.368" y2="45.075" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
