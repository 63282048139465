/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floor_new_heating__1': {
    width: 74,
    height: 48,
    viewBox: '0 0 74 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M72 44.999H53.73c.08-.27.13-.57.13-.87v-17.92h3.85c1.4 0 2.23-1.56 1.56-2.82-.11-.22-.27-.38-.3-.42-.01 0-.01-.01-.01-.01h-.01l-6.96-7.12v-9.31c0-1.06-.79-1.89-1.79-1.89h-3.78c-1 0-1.79.83-1.79 1.89v2.17l-6.39-6.21h-.01c-.67-.63-1.75-.68-2.42.02l-20.73 20.43c-1.01.98-.63 2.78.7 3.19.06.02.13.03.19.03H16l.11.03c.04.01.11.02.2.02h3.85v17.92c0 .3.04.59.12.87H2c-.55 0-1 .44-1 1s.45 1 1 1h70c.56 0 1-.45 1-1s-.44-1-1-1zm-19.86-.87c0 .36-.16.68-.42.87H22.3c-.26-.19-.43-.51-.43-.87v-12.73h30.27v12.73zm0-18.82v4.09H21.87v-4.09c0-.5-.38-.9-.85-.9h-4.69s-.05-.02-.09-.02a.076.076 0 01-.02-.05c-.01-.02 0-.03 0-.05l.02-.03 20.74-20.45s.01-.01.02-.01h.01s.06.01.08.02l7.82 7.59c.25.25.61.31.92.17.32-.15.52-.47.52-.83v-4.27-.02c.01 0 .01-.01.02-.01s.02-.01.04-.01h3.78s.03.01.04.01c.01 0 .01.01.02.01 0 .01.01.01.01.02s.01.02.01.05v9.69c0 .24.09.47.26.64l7.22 7.38s.01.02.02.02V24.369c-.01.01-.01.02-.02.03h-4.76c-.47 0-.86.4-.86.9l.01.01z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M29 26.969h4c.55 0 1-.51 1-1.14 0-.63-.45-1.14-1-1.14h-3v-3.43h3c.55 0 1-.51 1-1.14 0-.63-.45-1.14-1-1.14h-4c-.55 0-1 .51-1 1.14v5.71c0 .63.45 1.14 1 1.14zm12 0h4c.55 0 1-.51 1-1.14v-5.71c0-.63-.45-1.14-1-1.14h-4c-.55 0-1 .51-1 1.14 0 .63.45 1.14 1 1.14h3v3.43h-3c-.55 0-1 .51-1 1.14 0 .63.45 1.14 1 1.14z" _fill="url(#paint0_linear_2812_5278)"/><defs><linearGradient id="paint0_linear_2812_5278" x1="28" y1="26.969" x2="45.082" y2="17.348" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
