/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__oil': {
    width: 34,
    height: 48,
    viewBox: '0 0 34 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.189 1.396l-.75.524-.75-.525a.91.91 0 011.5.001zm-.751 2.16a189.892 189.892 0 00-6.504 10.45c-1.815 3.15-3.62 6.54-4.967 9.643-1.364 3.141-2.203 5.863-2.203 7.725 0 7.726 6.011 13.786 13.675 13.786 7.663 0 13.675-6.06 13.675-13.786 0-1.851-.839-4.568-2.203-7.707-1.348-3.103-3.153-6.494-4.968-9.646a191.448 191.448 0 00-6.505-10.465zm0-1.636l-.749-.525-.011.017-.032.046a87.856 87.856 0 00-.59.873 186.27 186.27 0 00-1.661 2.525 191.796 191.796 0 00-5.04 8.226c-1.831 3.179-3.673 6.636-5.06 9.83-1.372 3.157-2.356 6.186-2.356 8.462C1.939 40.116 8.767 47 17.439 47c8.671 0 15.5-6.884 15.5-15.626 0-2.266-.985-5.29-2.356-8.445-1.387-3.192-3.23-6.65-5.061-9.832a193.254 193.254 0 00-5.04-8.235 187.762 187.762 0 00-2.25-3.403l-.032-.047-.011-.016-.75.524z" _fill="#1A1A1A"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M26.014 31.004a.892.892 0 01.97.81l.002.012-.001-.012v.012l.003.021c0 .017.002.04.004.067a7.366 7.366 0 01-.026 1.004 8.044 8.044 0 01-.619 2.432c-.82 1.893-2.609 3.892-6.285 4.633a.89.89 0 01-1.045-.708.9.9 0 01.699-1.058c3.094-.623 4.41-2.224 5.003-3.59a6.228 6.228 0 00.503-2.462 3.542 3.542 0 00-.007-.178v-.004a.897.897 0 01.8-.979z" _fill="url(#paint0_linear_1824_4869)"/><defs><linearGradient id="paint0_linear_1824_4869" x1="19" y1="40" x2="28.529" y2="37.882" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
