/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__detached_house': {
    width: 53,
    height: 48,
    viewBox: '0 0 53 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M27 36.773c-1.13 0-2.056.821-2.056 1.874v6.467c0 .49-.435.886-.972.886-.537 0-.972-.397-.972-.886v-6.467C23 36.599 24.813 35 27 35c2.196 0 4 1.645 4 3.647v6.467c0 .49-.435.886-.972.886-.537 0-.972-.397-.972-.886v-6.467c0-1.023-.934-1.874-2.056-1.874z" _fill="url(#paint0_linear_1844_4146)"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M25.72 1.519c.724-.715 1.873-.66 2.582-.025l.013.012 6.824 6.343V5.63a1.9 1.9 0 011.908-1.924h4.027a1.9 1.9 0 011.908 1.924v9.517l7.43 7.278.009.01.005.004c.037.036.212.209.327.428.707 1.292-.18 2.883-1.67 2.883h-4.11v18.324c0 1.586-1.261 2.927-2.904 2.927H11.937a2.91 2.91 0 01-2.904-2.927V25.749h-4.11c-.09 0-.168-.01-.213-.018a2.777 2.777 0 01-.112-.02l-.005-.002-.028-.005a.908.908 0 01-.202-.036c-1.424-.422-1.827-2.256-.754-3.258l.003-.002L25.721 1.519zm1.265 1.324h.002-.002zm.005-.001a.125.125 0 01.097.025l8.346 7.758a.907.907 0 00.985.167.92.92 0 00.546-.843V5.63c0-.023.005-.037.008-.045a.056.056 0 01.012-.019.055.055 0 01.018-.012.116.116 0 01.045-.008h4.027c.022 0 .037.005.045.008a.055.055 0 01.018.012c.005.004.009.01.012.019a.119.119 0 01.008.045v9.905c0 .25.1.487.276.66l7.702 7.545a1.596 1.596 0 01.024.025.132.132 0 01.016.06.093.093 0 01-.012.045.076.076 0 01-.027.029.098.098 0 01-.053.011H44.06a.916.916 0 00-.913.92v19.244c0 .596-.47 1.087-1.079 1.087H11.937a1.078 1.078 0 01-1.078-1.087V24.829a.916.916 0 00-.913-.92H4.97l-.02-.004h-.004l-.098-.02a.097.097 0 01-.02-.042.105.105 0 010-.05.067.067 0 01.021-.033L26.978 2.853a.803.803 0 00.012-.011z" _fill="#000"/><defs><linearGradient id="paint0_linear_1844_4146" x1="23" y1="46" x2="32.68" y2="44.24" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
