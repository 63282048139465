/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_old__natural_gas': {
    width: 41,
    height: 48,
    viewBox: '0 0 41 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.261 26.51c-3.903 2.307-6.422 6.762-6.486 11.674v.188c0 3.696 2.791 6.71 6.214 6.71h.281c3.342-.072 5.996-3.071 5.933-6.691 0-.033 0-.065.003-.097.174-2.287-.862-4.486-2.629-5.684a5.868 5.868 0 01-1.929 2.407.834.834 0 01-.71.14.902.902 0 01-.572-.479c-1.273-2.565-1.264-5.62-.105-8.168zm.761-2.468c.543-.148 1.047.118 1.334.428a.95.95 0 01.133.184c.254.458.316 1.121-.046 1.65-1.24 1.834-1.588 4.205-.962 6.321a3.71 3.71 0 00.596-1.114c.135-.494.491-.741.592-.811l.016-.011c.388-.28.891-.293 1.29-.04 2.645 1.47 4.23 4.542 4.004 7.754.057 4.65-3.363 8.509-7.683 8.597h-.307C16.586 47 13 43.128 13 38.373v-.21c.077-6.17 3.552-11.72 8.81-14.058a.837.837 0 01.212-.063z" _fill="url(#paint0_linear_1824_4707)"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.991 1.41a.948.948 0 01.93-.398c7.03 1.123 12.363 5.248 15.81 10.115 3.047 4.3 4.677 9.26 4.669 13.367a15.935 15.935 0 004.251-2.647.945.945 0 01.887-.195c.309.09.552.332.645.643.53 1.768.817 3.622.817 5.519v.039C40 38.435 31.5 47 21 47S2 38.435 2 27.853a19.311 19.311 0 016.78-14.695c.245-.21.58-.28.889-.187.308.094.549.338.638.65a16.843 16.843 0 002.482 5.103 16.997 16.997 0 001.516-7.17v-.012c0-3.2-.813-6.324-2.364-9.115a.964.964 0 01.05-1.017zm2.499 1.88a20.65 20.65 0 011.716 8.246c.04 3.302-.82 6.554-2.41 9.43a.947.947 0 01-1.553.157 19.03 19.03 0 01-3.282-5.531 17.38 17.38 0 00-5.06 12.26c0 9.524 7.649 17.231 17.099 17.231s17.098-7.707 17.098-17.23v-.04c0-1.214-.13-2.411-.38-3.578a18.002 18.002 0 01-5.054 2.575.945.945 0 01-.893-.17.962.962 0 01-.343-.848c.415-3.781-1.018-8.997-4.244-13.552-2.892-4.082-7.168-7.551-12.694-8.95z" _fill="#1A1A1A"/><defs><linearGradient id="paint0_linear_1824_4707" x1="13" y1="47" x2="32.413" y2="43.624" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
