/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__301_400': {
    width: 49,
    height: 48,
    viewBox: '0 0 49 48',
    data: '<path pid="0" d="M45.352 1.572A6.424 6.424 0 0042.866 1H7.134C2.058 1 2 5.784 2 5.831c0 .562.452 1.014 1.013 1.018h.007c.558 0 1.013-.453 1.02-1.01.007-.467.187-2.798 3.094-2.798h35.729c.316 0 3.097.106 3.097 2.79h.024a3.624 3.624 0 00-.024.409v35.922c-.007.466-.187 2.797-3.094 2.797-.564 0-1.02.455-1.02 1.02S42.302 47 42.866 47C47.942 47 48 42.216 48 42.169V5.835c0-1.96-.942-3.474-2.648-4.263z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4.064 12.83v-.006l.003-.057a2.619 2.619 0 01.298-1.024 1.8 1.8 0 01.644-.693c.295-.181.741-.337 1.428-.337h18.77a1.031 1.031 0 100-2.062H6.437c-1.015 0-1.846.236-2.509.643a3.862 3.862 0 00-1.377 1.467 4.677 4.677 0 00-.55 2.034v.026l.01.008-.01-.007v29.99c0 .984.27 1.789.727 2.42a3.965 3.965 0 001.575 1.265 5.47 5.47 0 001.445.426 5.548 5.548 0 00.664.061h29.484c1.016 0 1.847-.236 2.51-.643a3.862 3.862 0 001.377-1.467 4.675 4.675 0 00.548-1.989l.001-.017v-.028l.001-.016v-.01h.002l-.002-.001V25.85a1.031 1.031 0 00-2.063 0V42.81l-.001.027-.002.03a2.617 2.617 0 01-.297 1.024c-.144.264-.347.51-.645.693-.295.181-.742.338-1.428.338H6.43l-.071-.004a3.416 3.416 0 01-1.192-.294 1.909 1.909 0 01-.767-.6c-.182-.252-.336-.625-.336-1.212V12.829zm31.73-1.875c-.753-.054-1.679-.054-2.852-.054a1.031 1.031 0 110-2.062h.032c1.135 0 2.132 0 2.966.059.833.059 1.615.182 2.273.489a3.02 3.02 0 011.551 1.6c.22.512.332 1.104.375 1.76.015.07.023.142.023.216V18.118a1.031 1.031 0 01-2.063 0v-4.64c0-.85-.094-1.362-.23-1.68a.964.964 0 00-.528-.543c-.31-.144-.785-.247-1.547-.3z" _fill="url(#paint0_linear_1844_1912)"/><defs><linearGradient id="paint0_linear_1844_1912" x1="2.001" y1="46.985" x2="47.099" y2="35.71" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
