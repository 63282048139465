/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pipeline_system_exists__yes': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8.4 43.8H4.9c-.9 0-1.9-.7-1.9-1.9v-9c0-.9.7-1.9 1.9-1.9h3.5c.9 0 1.9.7 1.9 1.9v9c0 .9-.7 1.9-1.9 1.9zm-3.3-2h3.2V33H5.1v8.8zM43 16.8h-3.5c-.9 0-1.9-.7-1.9-1.9v-9c0-.9.7-1.9 1.9-1.9H43c.9 0 1.9.7 1.9 1.9v9c0 .9-.7 1.9-1.9 1.9zm-3.3-2h3.2V6h-3.2v8.8z" _fill="#000"/><path pid="1" d="M16.2 42H9.3c-.6 0-1-.4-1-1v-7.2c0-.6.4-1 1-1h6.9c1.4 0 2.5-1.2 2.5-2.6v-3.6c0-.6.4-1 1-1h6.9c.6 0 1 .4 1 1v3.6c0 6.5-5.1 11.8-11.4 11.8zm-5.9-2h5.9c5.2 0 9.4-4.4 9.4-9.8v-2.6h-4.9v2.6c0 2.5-2 4.6-4.5 4.6h-5.9V40zM26.6 22.2h-6.9c-.6 0-1-.4-1-1v-3.6c0-6.5 5.1-11.8 11.4-11.8h8.6c.6 0 1 .4 1 1V14c0 .6-.4 1-1 1h-8.6c-1.4 0-2.5 1.2-2.5 2.6v3.6c0 .6-.4 1-1 1zm-5.9-2h4.9v-2.6c0-2.5 2-4.6 4.5-4.6h7.6V7.8h-7.6c-5.2 0-9.4 4.4-9.4 9.8v2.6z" _fill="#000"/><path pid="2" d="M27.5 27.6h-8.6c-.9 0-1.9-.7-1.9-1.9v-3.6c0-.9.7-1.9 1.9-1.9h8.6c.9 0 1.9.7 1.9 1.9v3.6c0 .9-.7 1.9-1.9 1.9zm-8.5-2h8.4v-3.4H19v3.4z" _fill="url(#paint0_linear_2632_1504)"/><defs><linearGradient id="paint0_linear_2632_1504" x1="17" y1="27.6" x2="30.186" y2="22.076" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
