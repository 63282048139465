/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__401_or_more': {
    width: 49,
    height: 48,
    viewBox: '0 0 49 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.766 45.177H43.245l.084-.005c.079-.006.198-.019.343-.045a3.583 3.583 0 001.046-.352c.365-.195.713-.477.973-.893.259-.413.463-1.01.463-1.888V19.391c0-.504.413-.912.923-.912s.923.408.923.912v22.603c0 1.17-.276 2.108-.738 2.846a4.36 4.36 0 01-1.667 1.539 5.445 5.445 0 01-1.994.599.932.932 0 01-.204.022H6.602a.932.932 0 01-.204-.022 5.445 5.445 0 01-1.994-.599 4.36 4.36 0 01-1.667-1.539c-.407-.651-.67-1.459-.727-2.443a.927.927 0 01-.01-.144V5.749h.92C2 5.748 2 5.746 2 5.746v-.075l.01-.159a5.377 5.377 0 01.616-2.116c.32-.593.81-1.203 1.553-1.662C4.924 1.275 5.871 1 7.052 1h22.815a.92.92 0 010 1.84H7.052c-.886 0-1.489.203-1.906.461-.42.26-.704.606-.9.97a3.54 3.54 0 00-.406 1.467V19.282a.911.911 0 01.006.109v22.603c0 .878.204 1.475.462 1.888.26.416.608.698.974.893a3.583 3.583 0 001.472.402h.012zM37.458 1a.958.958 0 100 1.917h5.75a2.875 2.875 0 012.876 2.875v5.75a.958.958 0 001.916 0v-5.75A4.792 4.792 0 0043.21 1h-5.75z" _fill="url(#paint0_linear_1844_1919)"/><defs><linearGradient id="paint0_linear_1844_1919" x1="48" y1="1" x2="-6.119" y2="14.53" gradientUnits="userSpaceOnUse"><stop stop-color="#B105F4"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#D10000"/></linearGradient></defs>'
  }
})
