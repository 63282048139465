<template>
    <div v-if="show" class="help-text__wrapper">
        <div class="help-text__text">
            {{ text }}
        </div>
        <div class="help-text__icon">
            <close-svg @click="close" />
        </div>
    </div>
</template>

<script>
import { eT } from '@/utils/eventTracking';
import CloseSvg from '../../public/svg/close.svg';

export default {
    components: {
        CloseSvg,
    },
    props: [
        'show',
        'text',
    ],
    data() {
        return {
            question: this.$route.params.id,
        };
    },
    watch: {
        /**
         * Event tracking.
         */
        show(newVal) {
            let eLabel = 'close';
            if (newVal) {
                eLabel = 'open';
            }
            eT({ event: `${eLabel}_help` });
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
