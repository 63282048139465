<template>
    <div>
        <div class="next-button"
             v-show="canNavigateNextStep">
            <button
                    :class="{ 't-btn-is-loading': loading || redirecting, [type]: true, }"
                    type="submit"
                    v-if="canNavigateNextStep"
                    @click="goNext"
                    :disabled="loading || redirecting"
                    tabindex="99">
                <span></span>
                {{ ctaLabel }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import navCommonMixin from '@/mixins/mixin.navCommon';
import { eT } from '@/utils/eventTracking';


export default {
    inject: ['$validator'],
    mixins: [navCommonMixin],
    props: {
        type: {
            type: String,
            default: 't-btn-2',
        },
    },
    computed: {
        ...mapState('ui', [
            'loading',
            'redirecting',
            'ctaLabel',
            'canNavigateNextStep',
        ]),
    },
    methods: {
        goNext() {
            eT({ event: 'click_forward' });
            this.navigateNextStep();
        },
    },
};
</script>
