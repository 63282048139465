<template>
    <div>
        <div class="lf-container">
            <app-info />
            <progress-bar />
            <div class="lf-container__body">
                <back-button />
                <zipcode-success v-if="this.$route.params.id === 'anfragen'" />
                <question />
                <transition
                    v-on:before-enter="beforeEnterTransition" name="slide-fade" mode="out-in">
                    <component :is="step.component"
                               :key="$route.params.id">
                    </component>
                </transition>
                <next-button v-if="this.$route.params.id !== 'anfragen'" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import navCommonMixin from '@/mixins/mixin.navCommon';
import AppInfo from '@/components/AppInfo.vue';
import Question from '@/components/Question.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import NextButton from '@/components/NextButton.vue';
import QuestionChoice from '@/components/QuestionChoice.vue';
import Zipcode from '@/components/Zipcode.vue';
import Inquire from '@/components/Inquire.vue';
import BackButton from '@/components/BackButton.vue';
import ZipcodeSuccess from '@/components/ZipcodeSuccess.vue';
import {
    getStepKeyFromRoute,
    getStep,
} from '../utils/appConfigGetters';

export default {
    inject: ['$validator'],
    data() {
        return {
            step: {},
        };
    },
    created() {
        this.init();
    },
    mixins: [
        navCommonMixin,
    ],
    components: {
        AppInfo,
        Question,
        ProgressBar,
        NextButton,
        QuestionChoice,
        Zipcode,
        Inquire,
        BackButton,
        ZipcodeSuccess,
    },
    methods: {
        init() {
            this.setStepData();
        },

        setStepData() {
            this.step = getStep(this.$route.params.id);
        },
    },
    beforeRouteUpdate(to, from, next) {
        /**
         * First guard: Redirect to beginning if route does not exist in the config.
         */
        if (
            !Object.prototype.hasOwnProperty.call(
                this.$root.appConfig.steps,
                to.params.id,
            )
        ) {
            this.$router.push({
                path: this.getStartingPath(),
            });
        }

        /**
         * Second guard: fix a complex issue.
         *
         * User goes through the following steps: 1 -> 2 -> 3 -> 4 -> 5 -> 4 -> 3 -> 2
         *
         * Note that the user used either the browser back button, or the app back button,
         * or any other way that triggers a window.history.back(). Since this app has branching,
         * we remove answers from the store when the user goes backward. See progressCleanup()
         * method for more information.
         *
         * Now, from that particular point, the user has the possibility to move forward using
         * the browser forward arrow, but the answers have been cleaned up, and the user
         * doesn't have to pick choices anymore to move forward. He can basically "skip"
         * questions now, which is a problem!
         *
         * This guard protects against this.
         */
        if (
            to.params.id !== this.getStartingPath()
                && Object.prototype.hasOwnProperty.call(
                    this.$root.appConfig.steps[to.params.id],
                    'backend_key',
                )
        ) {
            if (
                _.includes(
                    Object.keys(
                        this.$store.state.collectedData.answers,
                    ),
                    getStepKeyFromRoute(from.params.id),
                )
                    || _.includes(
                        Object.keys(
                            this.$store.state.collectedData.answers,
                        ),
                        getStepKeyFromRoute(to.params.id),
                    )
            ) {
                next();
            }
        } else {
            next();
        }
    },
    watch: {
        $route: {
            handler() {
                this.setStepData();
                if (window.innerWidth < 1024) {
                    this.$scrollTo('.lf-container');
                }
            },
            deep: true,
        },
    },
};
</script>
