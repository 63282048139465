<template>
    <div class="zipcode-success">
        <checkmark class="zipcode-success__icon"/>
        <div class="zipcode-success__text">
            <div class="zipcode-success__zipcode">
                {{ $store.state.collectedData.customer.zipcode }}
            </div>
        </div>
        <div class="zipcode-success__text--mobile">
            Wir sind bei Ihnen vor Ort.
        </div>
        <div class="zipcode-success__text--desktop">
            Gute Nachricht! Wir sind bei Ihnen vor Ort.
        </div>
    </div>
</template>

<script>
import Checkmark from '../../public/svg/checkmark.svg';

export default {
    components: {
        Checkmark,
    },
};
</script>
