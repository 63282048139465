<template>
    <div>
        <div class="back-button__wrapper"
            v-show="canNavigatePrevStep">
            <div v-if="canNavigatePrevStep"
                    @click="goBack"
                    tabindex="98"
            >
                <div class="back-button">
                    <BackSvg />
                    <div>Zurück</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import navCommonMixin from '@/mixins/mixin.navCommon';
import { eT } from '@/utils/eventTracking';
import BackSvg from '../../public/svg/back.svg';

export default {
    mixins: [navCommonMixin],
    components: {
        BackSvg,
    },
    methods: {
        goBack() {
            eT({ event: 'click_back' });
            this.navigatePrevStep();
            this.$store.commit('ui/setShowErrorInfoBox', false);
        },
    },
};
</script>
