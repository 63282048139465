<template>
    <div>
        <div class="progress-bar__container">
            <div class="progress-bar__text"> {{ getPercentage }}% geschafft</div>
            <div class="progress-bar">
                <div :style="{ width: getPercentage + '%' }"
                     class="progress-bar__progress">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getSteps,
    getStepKeyFromRoute,
} from '../utils/appConfigGetters';

export default {
    data() {
        return {
            arrayOfStepsToShow: [],
            percentage: 0,
        };
    },
    created() {
        Object
            .entries(getSteps())
            .forEach(([key, value]) => {
                if (value.count_as_progress) {
                    this.arrayOfStepsToShow.push(key);
                }
            });
        this.addProgressEntry();
    },
    computed: {
        getPercentage() {
            return this.$route.params.id === 'anfragen' ? '99' : this.percentage + 5;
        },
    },
    methods: {
        updatePercentage() {
            this.percentage = Math.round((
                this.arrayOfStepsToShow.indexOf(
                    this.$route.params.id,
                ) / this.arrayOfStepsToShow.length
            ) * 100);
        },

        /**
         * Add needed progress entries, mostly for the progress bar.
         */
        addProgressEntry() {
            /**
             * These lines are preventing the progress to store an unwanted step in case of a
             * page refresh.
             *
             * The first step stored should always be the entry defined in the scenario (see
             * app.json scenario file).
             *
             * See https://thermondo.atlassian.net/browse/MT-854 for more information.
             */

            /**
             * Note: This fix created another bug. We are disabling it for now. Will be worked on
             * asap.
             *
             * See https://thermondo.atlassian.net/browse/MT-1023
             */
            // const toStepKey = getStepKeyFromRoute(this.$route.params.id);
            //
            //
            // if (
            //     !this.$store.state.status.progress.length
            //     && toStepKey !== getStepKeyFromRoute(getEntryRoute())
            // ) {
            //     return;
            // }
            /**
             * End of https://thermondo.atlassian.net/browse/MT-854
             */

            this.$store.commit(
                'status/progress/addProgressEntry',
                getStepKeyFromRoute(
                    this.$route.params.id,
                ),
            );
        },

        /**
         * Guard function to clear all answers that are after my current offset.
         * Needed if the user navigates back from route A and decides to go for route B, to
         * avoid confusion in the collected data.
         */
        progressCleanup() {
            const { progress } = this.$store.state.status;
            const garbage = progress.slice(
                progress.indexOf(
                    getStepKeyFromRoute(
                        this.$route.params.id,
                    ),
                ) + 1,
            );
            garbage.forEach((key) => {
                this.$store.commit('collectedData/answers/removeAnswerEntry', key);
                this.$store.commit('status/progress/removeProgressEntry', key);
            });
        },
    },
    watch: {
        $route: {
            handler() {
                this.addProgressEntry();
                this.updatePercentage();
                this.progressCleanup();
            },
            deep: true,
        },
    },
};
</script>
