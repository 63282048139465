/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__no_installation': {
    width: 50,
    height: 48,
    viewBox: '0 0 50 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.045 34.523c0-6.878 5.6-12.478 12.477-12.478C42.4 22.045 48 27.645 48 34.523S42.4 47 35.523 47c-6.877 0-12.478-5.565-12.478-12.477zm12.477-10.63c-5.857 0-10.63 4.772-10.63 10.63 0 5.888 4.771 10.63 10.63 10.63 5.858 0 10.63-4.773 10.63-10.63 0-5.858-4.772-10.63-10.63-10.63z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M39.984 31.331a.957.957 0 00-1.353-1.353l-3.15 3.15-3.15-3.15a.957.957 0 10-1.353 1.353l3.15 3.15-3.15 3.15a.957.957 0 001.353 1.353l3.15-3.15 3.15 3.15a.957.957 0 001.353-1.353l-3.15-3.15 3.15-3.15z" _fill="url(#paint0_linear_1846_2080)"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M5.02 6.74c-.599 0-1.107.506-1.107 1.145v29.278c0 .653.522 1.145 1.107 1.145h16.44a.957.957 0 010 1.913H5.02C3.367 40.22 2 38.86 2 37.163V7.885c0-1.641 1.311-3.058 3.02-3.058h37.007c1.653 0 3.02 1.36 3.02 3.058V21.49a.957.957 0 11-1.913 0V7.885c0-.653-.522-1.145-1.107-1.145H5.02z" _fill="#000"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M12.523 1c.528 0 .956.428.956.957v10.522a.957.957 0 11-1.913 0V1.957c0-.529.428-.957.957-.957zM33.568 1c.528 0 .956.428.956.957v10.522a.957.957 0 01-1.913 0V1.957c0-.529.428-.957.957-.957zM2.957 15.734c0-.528.428-.957.956-.957h39.7a.957.957 0 010 1.914h-39.7a.957.957 0 01-.956-.957z" _fill="#000"/><defs><linearGradient id="paint0_linear_1846_2080" x1="30.698" y1="39.264" x2="41.952" y2="36.451" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
