<template>
    <div class="cost-saving-calculator">
        <div>
            Voraussichtliche Heizkostenersparnis:
            <span class="cost-saving-calculator__number__wrap">
                <span class="cost-saving-calculator__number">
                    {{ costSavingMinimum }}
                </span> –
                <span class="cost-saving-calculator__number">
                    {{ costSavingMaximum }} €
                </span>
            </span> pro Jahr
        </div>
        <div class="cost-saving-calculator__bar">
            <CostSavingBar />
            <div class="cost-saving-calculator__euro-sign">€€</div>
        </div>
    </div>
</template>
<script>
import CostSavingBar from '../../public/svg/cost-saving-bar.svg';
import costSavings from '../config/cost-savings.json';

export default {
    components: {
        CostSavingBar,
    },
    computed: {
        costSaving() {
            const {
                energy_source_old: sourceOld,
                energy_source_new: sourceNew,
                old_heating_energy_consumption: consumption,
            } = this.$store.state.collectedData.answers;

            if (sourceNew !== 'air_to_water_heat_pump') {
                return null;
            }

            if (costSavings[sourceOld]) {
                return costSavings[sourceOld][consumption];
            }

            return null;
        },
        costSavingMinimum() {
            const saving = this.costSaving;
            return saving ? saving.min : null;
        },
        costSavingMaximum() {
            const saving = this.costSaving;
            return saving ? saving.max : null;
        },
    },
};
</script>
