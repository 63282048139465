/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__interested': {
    width: 62,
    height: 49,
    viewBox: '0 0 62 49',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 .3a1 1 0 011 1v2.566a1 1 0 11-2 0V1.3a1 1 0 011-1zM5.38 5.724a1 1 0 011.414 0l2.565 2.565a1 1 0 11-1.414 1.414L5.38 7.138a1 1 0 010-1.414zm37.197 1.414a1 1 0 10-1.414-1.414l-2.566 2.565a1 1 0 001.415 1.414l2.565-2.565zM0 24.3a1 1 0 011-1h2.565a1 1 0 110 2H1a1 1 0 01-1-1zm36.184-10.792c-5.977-6.765-16.307-7.364-23.066-1.346l-.002.002a16.365 16.365 0 00-5.464 12.18c0 1.815.326 3.622.926 5.331a1 1 0 101.887-.663 14.124 14.124 0 01-.813-4.668c0-4.062 1.747-7.965 4.796-10.689 5.937-5.285 14.998-4.753 20.237 1.177a1 1 0 001.5-1.324z" _fill="url(#paint0_linear_1846_3958)"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M28.044 23.324a1 1 0 01.758 1.194l-5.13 23a1 1 0 11-1.952-.436l5.13-23a1 1 0 011.194-.758zM37.826 23.324a1 1 0 011.193.758l5.13 23a1 1 0 01-1.951.436l-5.13-23a1 1 0 01.758-1.194z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M13.609 31.953a1 1 0 011-1h36.608a1 1 0 010 2H14.61a1 1 0 01-1-1zM9.348 39.648a1 1 0 011-1h45.13a1 1 0 010 2h-45.13a1 1 0 01-1-1z" _fill="#000"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M17.524 24.89L6.413 44.898c-.268.394-.326.825-.326 1.141a2.262 2.262 0 002.26 2.261h49.305c.458 0 .848-.175 1.056-.28a.972.972 0 00.049-.025 2.28 2.28 0 00.854-3.098l-11.345-20.43a.975.975 0 00-.043-.07c-.403-.604-1.079-1.097-1.962-1.097H19.652c-.835 0-1.608.453-2.004 1.167a1 1 0 00-.124.423zm2.032.425a.993.993 0 01-.073.166L8.135 45.916a.993.993 0 01-.046.075.44.44 0 00-.002.048c0 .144.117.261.26.261h49.295a.904.904 0 00.14-.053.28.28 0 00.089-.364l-.006-.01L46.54 25.48a.522.522 0 00-.148-.143.243.243 0 00-.132-.037H19.652a.316.316 0 00-.096.015z" _fill="#000"/><defs><linearGradient id="paint0_linear_1846_3958" x1="0" y1="30.344" x2="47.538" y2="13.386" gradientUnits="userSpaceOnUse"><stop stop-color="#D10000"/><stop offset=".501" stop-color="#F60439"/><stop offset="1" stop-color="#B105F4"/></linearGradient></defs>'
  }
})
